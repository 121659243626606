let appConfig;

function setAppConfig(config) {
  appConfig = config;
}

function getAppConfig() {
  return appConfig;
}

export { setAppConfig, getAppConfig };
