import appBridge from '@yg/app-bridge';

import { PagePluginsOptions } from '../types';
import ta from './ta_js_sdk/thinkingdata.esm.min';

export default {
  init: (options: PagePluginsOptions) => {
    ta.init({
      appId: options.config.thinkDataAppId,
      serverUrl: 'https://thinkingdata.falla.live/sync_js',
      mode: options.config.isDev ? 'debug' : undefined,
      showLog: options.config.isDev,
      autoTrack: {
        // 开启ta_page_show事件
        pageShow: false,
        // 开启ta_page_hide事件
        pageHide: false,
      },
    });
    const { uid, region } = appBridge.getAppUserInfo();
    const userDeviceInfo = appBridge.getUserDeviceInfo();
    const accountId = uid ? String(uid) : '';

    accountId ? ta.login(accountId) : ta.logout();
    ta.setPageProperty({
      region: region || 'EN',
      module_name: options.package.name,
      user_device_info: userDeviceInfo,
    });
    window.ta = ta;
  },
  ta,
};
