/**
 * utils
 *
 * @author fukui
 */
export function processStackMsg(error: Error) {
  let stack = (error.stack || '')
    .replace(/\n/gi, '')
    .split(/\bat\b/)
    .slice(0, 9)
    .join('@')
    .replace(/\?[^:]+/gi, '');
  const msg = error.toString();
  if (stack.indexOf(msg) < 0) {
    stack = `${msg}@${stack}`;
  }
  return stack;
}

export function getParameter(query: string): string {
  const matchArray = window.location.hash.match(new RegExp(`(?:#|&)${query}=([^&]*)(&|$)`));
  const result = !matchArray ? '' : decodeURIComponent(matchArray[1]);
  return result || getParameterByName(query);
}

function getParameterByName(name: string, url?: string): string {
  if (!url) url = window.location.href;
  name = name.replace(/[[]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return '';
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
