import { store } from '@ice/stark-data';
import { useTranslation } from 'react-i18next';

const overwriteNotReadyT = (key: string, options: string) => {
  return options || key || '';
};

export const useStarkTranslation = () => {
  const res = useTranslation('', { i18n: store.get('i18n') });

  return {
    ...res,
    t: res.ready ? res.t : overwriteNotReadyT,
  };
};

export default useStarkTranslation;
