/**
 * index
 *
 * @author fukui
 */

import { PagePluginsOptions } from '../types';
import aegisSdk from './aegis-sdk';
import PerformanceMonitor from './performance-monitor/index';

const init = (options: PagePluginsOptions) => {
  const packageInfo = options.package;
  // eslint-disable-next-line no-new
  const monitor = new PerformanceMonitor({});
  if (!options.config.isHostProd) monitor.showVConsole(false);
  aegisSdk.init(packageInfo);
};

export default { init };
