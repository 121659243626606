import { iConfig } from '@falla/config/src/config';
import PagePlugins from '@falla/page-plugins/src/index';
import { isInIcestark } from '@ice/stark-app';

import packageInfo from '../../package.json';

const isInIcestarkTrue = isInIcestark();

PagePlugins.init({
  package: packageInfo,
  config: iConfig,
  isInMain: false,
  init: {
    // 非微前端应用 或者 微前端的本地环境
    i18n: !isInIcestarkTrue || (isInIcestarkTrue && iConfig.isDev),
    error: !isInIcestarkTrue || (isInIcestarkTrue && iConfig.isDev),
    flexible: false,
    firebase: !isInIcestarkTrue || (isInIcestarkTrue && iConfig.isDev),
  },
});
