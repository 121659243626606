import { px2rem } from '@yg/utils/esm/format-util';
import classNames from 'classnames';
import * as React from 'react';

import styles from './index.module.less';

export type SpacingProps = {
  width?: number | string;
  height?: number | string;
  background?: string;
} & BaseProps;

const Spacing = (props: SpacingProps) => {
  const { width, height, background, style } = props;
  return (
    <div
      className={classNames(styles.spacing, props.className)}
      style={{
        height: px2rem(height),
        width: px2rem(width),
        background,
        ...style,
      }}
    />
  );
};

Spacing.defaultProps = {
  height: 40,
  width: '100%',
};

export default Spacing;
