import { PagePluginsOptions } from '../types';
// eslint-disable-next-line import/no-named-as-default
import firebaseAnalytics from './firebase-analytics';

export default {
  init: (options: PagePluginsOptions) => {
    firebaseAnalytics.init(options.package.name, options.config.firebaseConfig);
  },
  logEvent: firebaseAnalytics.logEvent,
  customEvent: firebaseAnalytics.customEvent,
};
