import './toast.less';

import { clear, config, show } from './methods';

export type { ToastHandler, ToastShowProps } from './methods';

const toast = {
  show,
  clear,
  config,
};

// @ts-ignore
window._fallaMicro_toast = (window._fallaMicro_toast as typeof toast) || toast;

// @ts-ignore
export const Toast: typeof toast = window._fallaMicro_toast;

export default Toast;
