import useGaReport from '@falla/hooks/src/useGaReport';
import React from 'react';

const GaWrapperPage = (WrappedComponent: React.ComponentType<any>) => {
  return (props: any) => {
    const gaReport = useGaReport();
    React.useEffect(() => {
      gaReport.reportPageShow();
    }, []);
    return <WrappedComponent {...props} />;
  };
};

export default GaWrapperPage;

// 以下是 thinking data 流程
/*

import useReport from '@falla/hooks/src/useReport';
import React, { useEffect } from 'react';

const ReportWrapperPage = (WrappedComponent: React.ComponentType<any>) => {
  return (props: any) => {
    // @ts-ignore
    const report = useReport();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    // @ts-ignore
    React.useEffect(() => {
      report.reportPageShow();
    }, []);
    return <WrappedComponent {...props} />;
  };
};

export default ReportWrapperPage;

 */
