function _typeof(e) {
  return (_typeof =
    'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
      ? function (e) {
          return typeof e;
        }
      : function (e) {
          return e && 'function' == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype
            ? 'symbol'
            : typeof e;
        })(e);
}
function _classCallCheck(e, t) {
  if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
}
function _defineProperties(e, t) {
  for (var n = 0; n < t.length; n++) {
    var r = t[n];
    (r.enumerable = r.enumerable || !1),
      (r.configurable = !0),
      'value' in r && (r.writable = !0),
      Object.defineProperty(e, r.key, r);
  }
}
function _createClass(e, t, n) {
  return (
    t && _defineProperties(e.prototype, t),
    n && _defineProperties(e, n),
    Object.defineProperty(e, 'prototype', { writable: !1 }),
    e
  );
}
'object' !== ('undefined' == typeof JSON ? 'undefined' : _typeof(JSON)) && (JSON = {}),
  (function () {
    var rx_one = /^[\],:{}\s]*$/,
      rx_two = /\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g,
      rx_three = /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
      rx_four = /(?:^|:|,)(?:\s*\[)+/g,
      rx_escapable =
        /[\\"\u0000-\u001f\u007f-\u009f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
      rx_dangerous =
        /[\u0000\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
      gap,
      indent,
      meta,
      rep;
    function f(e) {
      return e < 10 ? '0' + e : e;
    }
    function this_value() {
      return this.valueOf();
    }
    function quote(e) {
      return (
        (rx_escapable.lastIndex = 0),
        rx_escapable.test(e)
          ? '"' +
            e.replace(rx_escapable, function (e) {
              var t = meta[e];
              return 'string' == typeof t ? t : '\\u' + ('0000' + e.charCodeAt(0).toString(16)).slice(-4);
            }) +
            '"'
          : '"' + e + '"'
      );
    }
    function str(e, t) {
      var n,
        r,
        i,
        o,
        s,
        a = gap,
        c = t[e];
      switch (
        (c && 'object' === _typeof(c) && 'function' == typeof c.toJSON && (c = c.toJSON(e)),
        _typeof((c = 'function' == typeof rep ? rep.call(t, e, c) : c)))
      ) {
        case 'string':
          return quote(c);
        case 'number':
          return isFinite(c) ? String(c) : 'null';
        case 'boolean':
        case 'null':
          return String(c);
        case 'object':
          if (!c) return 'null';
          if (((gap += indent), (s = []), '[object Array]' === Object.prototype.toString.apply(c))) {
            for (o = c.length, n = 0; n < o; n += 1) s[n] = str(n, c) || 'null';
            return (
              (i =
                0 === s.length
                  ? '[]'
                  : gap
                  ? '[\n' + gap + s.join(',\n' + gap) + '\n' + a + ']'
                  : '[' + s.join(',') + ']'),
              (gap = a),
              i
            );
          }
          if (rep && 'object' === _typeof(rep))
            for (o = rep.length, n = 0; n < o; n += 1)
              'string' == typeof rep[n] && (i = str((r = rep[n]), c)) && s.push(quote(r) + (gap ? ': ' : ':') + i);
          else
            for (r in c)
              Object.prototype.hasOwnProperty.call(c, r) &&
                (i = str(r, c)) &&
                s.push(quote(r) + (gap ? ': ' : ':') + i);
          return (
            (i =
              0 === s.length
                ? '{}'
                : gap
                ? '{\n' + gap + s.join(',\n' + gap) + '\n' + a + '}'
                : '{' + s.join(',') + '}'),
            (gap = a),
            i
          );
      }
    }
    'function' != typeof Date.prototype.toJSON &&
      ((Date.prototype.toJSON = function () {
        return isFinite(this.valueOf())
          ? this.getUTCFullYear() +
              '-' +
              f(this.getUTCMonth() + 1) +
              '-' +
              f(this.getUTCDate()) +
              'T' +
              f(this.getUTCHours()) +
              ':' +
              f(this.getUTCMinutes()) +
              ':' +
              f(this.getUTCSeconds()) +
              'Z'
          : null;
      }),
      (Boolean.prototype.toJSON = this_value),
      (Number.prototype.toJSON = this_value),
      (String.prototype.toJSON = this_value)),
      'function' != typeof JSON.stringify &&
        ((meta = { '\b': '\\b', '\t': '\\t', '\n': '\\n', '\f': '\\f', '\r': '\\r', '"': '\\"', '\\': '\\\\' }),
        (JSON.stringify = function (e, t, n) {
          var r;
          if (((indent = gap = ''), 'number' == typeof n)) for (r = 0; r < n; r += 1) indent += ' ';
          else 'string' == typeof n && (indent = n);
          if ((rep = t) && 'function' != typeof t && ('object' !== _typeof(t) || 'number' != typeof t.length))
            throw new Error('JSON.stringify');
          return str('', { '': e });
        })),
      'function' != typeof JSON.parse &&
        (JSON.parse = function (text, reviver) {
          var j;
          function walk(e, t) {
            var n,
              r,
              i = e[t];
            if (i && 'object' === _typeof(i))
              for (n in i)
                Object.prototype.hasOwnProperty.call(i, n) && (void 0 !== (r = walk(i, n)) ? (i[n] = r) : delete i[n]);
            return reviver.call(e, t, i);
          }
          if (
            ((text = String(text)),
            (rx_dangerous.lastIndex = 0),
            rx_dangerous.test(text) &&
              (text = text.replace(rx_dangerous, function (e) {
                return '\\u' + ('0000' + e.charCodeAt(0).toString(16)).slice(-4);
              })),
            rx_one.test(text.replace(rx_two, '@').replace(rx_three, ']').replace(rx_four, '')))
          )
            return (j = eval('(' + text + ')')), 'function' == typeof reviver ? walk({ '': j }, '') : j;
          throw new SyntaxError('JSON.parse');
        });
  })();
var Config = { LIB_VERSION: '1.6.1' },
  MAX_REFERRER_STRING_LENGTH = 200,
  ArrayProto = Array.prototype,
  ObjProto = Object.prototype,
  slice = ArrayProto.slice,
  toString = ObjProto.toString,
  hasOwnProperty = ObjProto.hasOwnProperty,
  nativeForEach = ArrayProto.forEach,
  breaker = {},
  utmTypes = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'],
  _ = {
    each: function (e, t, n) {
      if (null !== e)
        if (nativeForEach && e.forEach === nativeForEach) e.forEach(t, n);
        else if (e.length === +e.length) {
          for (var r = 0, i = e.length; r < i; r++) if (r in e && t.call(n, e[r], r, e) === breaker) return;
        } else for (var o in e) if (hasOwnProperty.call(e, o) && t.call(n, e[o], o, e) === breaker) return;
    },
    extend: function (n) {
      return (
        _.each(slice.call(arguments, 1), function (e) {
          for (var t in e) void 0 !== e[t] && (n[t] = e[t]);
        }),
        n
      );
    },
    formatDate: function (e) {
      function t(e) {
        return e < 10 ? '0' + e : e;
      }
      return (
        e.getFullYear() +
        '-' +
        t(e.getMonth() + 1) +
        '-' +
        t(e.getDate()) +
        ' ' +
        t(e.getHours()) +
        ':' +
        t(e.getMinutes()) +
        ':' +
        t(e.getSeconds()) +
        '.' +
        ((n = e.getMilliseconds()) < 10 ? '00' + n : n < 100 ? '0' + n : n)
      );
      var n;
    },
    formatTimeZone: function (e, t) {
      if ('number' != typeof t) return e;
      var n = e.getTime(),
        e = 6e4 * e.getTimezoneOffset();
      return new Date(n + e + 36e5 * t);
    },
    formatJsonString: function (t) {
      try {
        return JSON.stringify(t, null, 8);
      } catch (e) {
        return JSON.stringify(t);
      }
    },
    searchObjDate: function (n, r) {
      (_.check.isObject(n) || _.check.isArray(n)) &&
        _.each(n, function (e, t) {
          _.check.isObject(e) || _.check.isArray(e)
            ? _.searchObjDate(n[t], r)
            : _.check.isDate(e) && (n[t] = _.formatDate(_.formatTimeZone(e, r)));
        });
    },
    check: {
      isUndefined: function (e) {
        return void 0 === e;
      },
      isObject: function (e) {
        return '[object Object]' === toString.call(e) && null !== e;
      },
      isEmptyObject: function (e) {
        if (_.check.isObject(e)) {
          for (var t in e) if (hasOwnProperty.call(e, t)) return !1;
          return !0;
        }
        return !1;
      },
      isArray: function (e) {
        return '[object Array]' === toString.call(e);
      },
      isString: function (e) {
        return '[object String]' === toString.call(e);
      },
      isDate: function (e) {
        return '[object Date]' === toString.call(e);
      },
      isNumber: function (e) {
        return '[object Number]' === toString.call(e);
      },
      isBoolean: function (e) {
        return '[object Boolean]' === toString.call(e);
      },
      isJSONString: function (e) {
        try {
          JSON.parse(e);
        } catch (e) {
          return !1;
        }
        return !0;
      },
    },
  };
(_.UUID = (function () {
  function t() {
    for (var e = +new Date(), t = 0; e === +new Date(); ) t++;
    return e.toString(16) + t.toString(16);
  }
  return function () {
    var e =
        (e = String(screen.height * screen.width)) && /\d{5,}/.test(e)
          ? e.toString(16)
          : String(31242 * Math.random())
              .replace('.', '')
              .slice(0, 8),
      e =
        t() +
        '-' +
        Math.random().toString(16).replace('.', '') +
        '-' +
        (function () {
          var e,
            t,
            n = navigator.userAgent,
            i = [],
            r = 0;
          function o(e, t) {
            for (var n = 0, r = 0; r < t.length; r++) n |= i[r] << (8 * r);
            return e ^ n;
          }
          for (e = 0; e < n.length; e++)
            (t = n.charCodeAt(e)), i.unshift(255 & t), 4 <= i.length && ((r = o(r, i)), (i = []));
          return (r = 0 < i.length ? o(r, i) : r).toString(16);
        })() +
        '-' +
        e +
        '-' +
        t();
    return e || (String(Math.random()) + String(Math.random()) + String(Math.random())).slice(2, 15);
  };
})()),
  (_.UUIDv4 = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (e) {
      var t = (16 * Math.random()) | 0;
      return ('x' == e ? t : (3 & t) | 8).toString(16);
    });
  }),
  (_.getReferrer = function (e) {
    e = e || document.referrer;
    return 'string' != typeof e
      ? '取值异常_referrer异常_' + String(e)
      : 'string' ==
        typeof (e = (e = 0 === e.indexOf('https://www.baidu.com/') ? e.split('?')[0] : e).slice(
          0,
          MAX_REFERRER_STRING_LENGTH,
        ))
      ? e
      : '';
  }),
  (_.url = (function () {
    function i() {
      return new RegExp(
        /(.*?)\.?([^\.]*?)\.(com|net|org|biz|ws|in|me|co\.uk|co|org\.uk|ltd\.uk|plc\.uk|me\.uk|edu|mil|br\.com|cn\.com|eu\.com|hu\.com|no\.com|qc\.com|sa\.com|se\.com|se\.net|us\.com|uy\.com|ac|co\.ac|gv\.ac|or\.ac|ac\.ac|af|am|as|at|ac\.at|co\.at|gv\.at|or\.at|asn\.au|com\.au|edu\.au|org\.au|net\.au|id\.au|be|ac\.be|adm\.br|adv\.br|am\.br|arq\.br|art\.br|bio\.br|cng\.br|cnt\.br|com\.br|ecn\.br|eng\.br|esp\.br|etc\.br|eti\.br|fm\.br|fot\.br|fst\.br|g12\.br|gov\.br|ind\.br|inf\.br|jor\.br|lel\.br|med\.br|mil\.br|net\.br|nom\.br|ntr\.br|odo\.br|org\.br|ppg\.br|pro\.br|psc\.br|psi\.br|rec\.br|slg\.br|tmp\.br|tur\.br|tv\.br|vet\.br|zlg\.br|br|ab\.ca|bc\.ca|mb\.ca|nb\.ca|nf\.ca|ns\.ca|nt\.ca|on\.ca|pe\.ca|qc\.ca|sk\.ca|yk\.ca|ca|cc|ac\.cn|net\.cn|com\.cn|edu\.cn|gov\.cn|org\.cn|bj\.cn|sh\.cn|tj\.cn|cq\.cn|he\.cn|nm\.cn|ln\.cn|jl\.cn|hl\.cn|js\.cn|zj\.cn|ah\.cn|gd\.cn|gx\.cn|hi\.cn|sc\.cn|gz\.cn|yn\.cn|xz\.cn|sn\.cn|gs\.cn|qh\.cn|nx\.cn|xj\.cn|tw\.cn|hk\.cn|mo\.cn|cn|cx|cz|de|dk|fo|com\.ec|tm\.fr|com\.fr|asso\.fr|presse\.fr|fr|gf|gs|co\.il|net\.il|ac\.il|k12\.il|gov\.il|muni\.il|ac\.in|co\.in|org\.in|ernet\.in|gov\.in|net\.in|res\.in|is|it|ac\.jp|co\.jp|go\.jp|or\.jp|ne\.jp|ac\.kr|co\.kr|go\.kr|ne\.kr|nm\.kr|or\.kr|li|lt|lu|asso\.mc|tm\.mc|com\.mm|org\.mm|net\.mm|edu\.mm|gov\.mm|ms|nl|no|nu|pl|ro|org\.ro|store\.ro|tm\.ro|firm\.ro|www\.ro|arts\.ro|rec\.ro|info\.ro|nom\.ro|nt\.ro|se|si|com\.sg|org\.sg|net\.sg|gov\.sg|sk|st|tf|ac\.th|co\.th|go\.th|mi\.th|net\.th|or\.th|tm|to|com\.tr|edu\.tr|gov\.tr|k12\.tr|net\.tr|org\.tr|com\.tw|org\.tw|net\.tw|ac\.uk|uk\.com|uk\.net|gb\.com|gb\.net|vg|sh|kz|ch|info|ua|gov|name|pro|ie|hk|com\.hk|org\.hk|net\.hk|edu\.hk|us|tk|cd|by|ad|lv|eu\.lv|bz|es|jp|cl|ag|mobi|eu|co\.nz|org\.nz|net\.nz|maori\.nz|iwi\.nz|io|la|md|sc|sg|vc|tw|travel|my|se|tv|pt|com\.pt|edu\.pt|asia|fi|com\.ve|net\.ve|fi|org\.ve|web\.ve|info\.ve|co\.ve|tel|im|gr|ru|net\.ru|org\.ru|hr|com\.hr|ly|xyz)$/,
      );
    }
    function o(e, t) {
      var n = e.charAt(0),
        t = t.split(n);
      return n === e ? t : t[(e = parseInt(e.substring(1), 10)) < 0 ? t.length + e : e - 1];
    }
    function s(e, t) {
      for (
        var n, r, i = e.charAt(0), o = t.split('&'), s = [], a = {}, c = e.substring(1), u = 0, h = o.length;
        u < h;
        u++
      )
        if ('' !== (s = (s = o[u].match(/(.*?)=(.*)/)) || [o[u], o[u], ''])[1].replace(/\s/g, '')) {
          if (((s[2] = ((r = s[2] || ''), _.decodeURIComponent(r.replace(/\+/g, ' ')))), c === s[1])) return s[2];
          (n = s[1].match(/(.*)\[([0-9]+)\]/)) ? ((a[n[1]] = a[n[1]] || []), (a[n[1]][n[2]] = s[2])) : (a[s[1]] = s[2]);
        }
      return i === e ? a : a[c];
    }
    return function (e, t) {
      var n = {};
      if ('tld?' === e) return i();
      if (((t = t || window.location.toString()), !e)) return t;
      if (((e = e.toString()), (r = t.match(/^mailto:([^\/].+)/)))) (n.protocol = 'mailto'), (n.email = r[1]);
      else {
        if (
          ((r = (t = (r = t.match(/(.*?)\/#\!(.*)/)) ? r[1] + r[2] : t).match(/(.*?)#(.*)/)) &&
            ((n.hash = r[2]), (t = r[1])),
          n.hash && e.match(/^#/))
        )
          return s(e, n.hash);
        if (((r = t.match(/(.*?)\?(.*)/)) && ((n.query = r[2]), (t = r[1])), n.query && e.match(/^\?/)))
          return s(e, n.query);
        if (
          ((r = t.match(/(.*?)\:?\/\/(.*)/)) && ((n.protocol = r[1].toLowerCase()), (t = r[2])),
          (r = t.match(/(.*?)(\/.*)/)) && ((n.path = r[2]), (t = r[1])),
          (n.path = (n.path || '').replace(/^([^\/])/, '/$1').replace(/\/$/, '')),
          (e = e.match(/^[\-0-9]+$/) ? e.replace(/^([^\/])/, '/$1') : e).match(/^\//))
        )
          return o(e, n.path.substring(1));
        if (
          ((r = (r = o('/-1', n.path.substring(1))) && r.match(/(.*?)\.(.*)/)) &&
            ((n.file = r[0]), (n.filename = r[1]), (n.fileext = r[2])),
          (r = t.match(/(.*)\:([0-9]+)$/)) && ((n.port = r[2]), (t = r[1])),
          (r = t.match(/(.*?)@(.*)/)) && ((n.auth = r[1]), (t = r[2])),
          n.auth && ((r = n.auth.match(/(.*)\:(.*)/)), (n.user = r ? r[1] : n.auth), (n.pass = r ? r[2] : void 0)),
          (n.hostname = t.toLowerCase()),
          '.' === e.charAt(0))
        )
          return o(e, n.hostname);
        i() &&
          (r = n.hostname.match(i())) &&
          ((n.tld = r[3]), (n.domain = r[2] ? r[2] + '.' + r[3] : void 0), (n.sub = r[1] || void 0));
        var r = n.port ? ':' + n.port : '';
        (n.protocol = n.protocol || window.location.protocol.replace(':', '')),
          (n.port = n.port || ('https' === n.protocol ? '443' : '80')),
          (n.protocol = n.protocol || ('443' === n.port ? 'https' : 'http')),
          (n.basic = n.protocol + '://' + n.hostname + r);
      }
      return e in n ? n[e] : '{}' === e ? n : '';
    };
  })()),
  (_.hashCode = function (e) {
    if ('string' != typeof e) return 0;
    var t = 0;
    if (0 === e.length) return t;
    for (var n = 0; n < e.length; n++) (t = (t << 5) - t + e.charCodeAt(n)), (t &= t);
    return t;
  }),
  (_.decodeURIComponent = function (t) {
    var n = '';
    try {
      n = decodeURIComponent(t);
    } catch (e) {
      n = t;
    }
    return n;
  }),
  (_.encodeURIComponent = function (t) {
    var n = '';
    try {
      n = encodeURIComponent(t);
    } catch (e) {
      n = t;
    }
    return n;
  }),
  (_.utf8Encode = function (e) {
    for (
      var t, n = '', r = (t = 0), i = (e = (e + '').replace(/\r\n/g, '\n').replace(/\r/g, '\n')).length, o = 0;
      o < i;
      o++
    ) {
      var s = e.charCodeAt(o),
        a = null;
      s < 128
        ? t++
        : (a =
            127 < s && s < 2048
              ? String.fromCharCode((s >> 6) | 192, (63 & s) | 128)
              : String.fromCharCode((s >> 12) | 224, ((s >> 6) & 63) | 128, (63 & s) | 128)),
        null !== a && (r < t && (n += e.substring(r, t)), (n += a), (r = t = o + 1));
    }
    return r < t && (n += e.substring(r, e.length)), n;
  }),
  (_.base64Encode = function (e) {
    var t,
      n,
      r,
      i,
      o = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
      s = 0,
      a = 0,
      c = '',
      u = [];
    if (!e) return e;
    for (
      e = _.utf8Encode(e);
      (t = ((i = (e.charCodeAt(s++) << 16) | (e.charCodeAt(s++) << 8) | e.charCodeAt(s++)) >> 12) & 63),
        (n = (i >> 6) & 63),
        (r = 63 & i),
        (u[a++] = o.charAt((i >> 18) & 63) + o.charAt(t) + o.charAt(n) + o.charAt(r)),
        s < e.length;

    );
    switch (((c = u.join('')), e.length % 3)) {
      case 1:
        c = c.slice(0, -2) + '==';
        break;
      case 2:
        c = c.slice(0, -1) + '=';
    }
    return c;
  }),
  (_.cookie = {
    get: function (e) {
      for (var t = e + '=', n = document.cookie.split(';'), r = 0; r < n.length; r++) {
        for (var i = n[r]; ' ' === i.charAt(0); ) i = i.substring(1, i.length);
        if (0 === i.indexOf(t)) return _.decodeURIComponent(i.substring(t.length, i.length));
      }
      return null;
    },
    set: function (e, t, n, r, i) {
      var o,
        s = '',
        a = '',
        c = '';
      (n = null === n ? 73e3 : n),
        r && (s = (o = _.url('domain', location.href)) ? '; domain=.' + o : ''),
        n &&
          ((o = new Date()),
          's' === String(n).slice(-1)
            ? o.setTime(o.getTime() + 1e3 * Number(String(n).slice(0, -1)))
            : o.setTime(o.getTime() + 24 * n * 60 * 60 * 1e3),
          (a = '; expires=' + o.toGMTString())),
        i && (c = '; secure');
      c = e + '=' + encodeURIComponent(t) + a + '; path=/' + s + c;
      return (document.cookie = c);
    },
    remove: function (e, t) {
      _.cookie.set(e, '', -1, t);
    },
  }),
  (_.localStorage = {
    get: function (e) {
      try {
        return window.localStorage.getItem(e);
      } catch (e) {
        _.localStorage.error(e);
      }
    },
    parse: function (e) {
      var t;
      try {
        t = JSON.parse(_.localStorage.get(e)) || null;
      } catch (e) {
        _.localStorage.error(e);
      }
      return t;
    },
    set: function (e, t) {
      try {
        window.localStorage.setItem(e, t);
      } catch (e) {
        _.localStorage.error(e);
      }
    },
    remove: function (e) {
      try {
        window.localStorage.removeItem(e);
      } catch (e) {
        _.localStorage.error(e);
      }
    },
    error: function (e) {
      console.error('localStorage error: ' + e);
    },
    isSupported: function () {
      var t = !0;
      try {
        var e = '__thinkingdatasupport__',
          n = 'testIsSupportStorage';
        _.localStorage.set(e, n), _.localStorage.get(e) !== n && (t = !1), _.localStorage.remove(e);
      } catch (e) {
        t = !1;
      }
      return t;
    },
  }),
  (_.stripEmptyProperties = function (e) {
    var n = {};
    return (
      _.each(e, function (e, t) {
        _.check.isString(e) && 0 < e.length && (n[t] = e);
      }),
      n
    );
  }),
  (_.info = {
    os: function () {
      var e = navigator.userAgent;
      return /Windows/i.test(e)
        ? /Phone/.test(e) || /WPDesktop/.test(e)
          ? 'Windows Phone'
          : 'Windows'
        : /(iPhone|iPad|iPod)/.test(e)
        ? 'iOS'
        : /Android/.test(e)
        ? 'Android'
        : /(BlackBerry|PlayBook|BB10)/i.test(e)
        ? 'BlackBerry'
        : /Mac/i.test(e)
        ? 'Mac OS X'
        : /Linux/.test(e)
        ? 'Linux'
        : /CrOS/.test(e)
        ? 'Chrome OS'
        : '';
    },
    browser: function () {
      var e = { type: '', version: '' };
      try {
        var t,
          n,
          r = navigator.userAgent.toLowerCase(),
          i = [];
        null !== r.match(/baidubrowser/)
          ? ((e.type = 'baidu'), i.push(/baidubrowser\/([\d.]+)/))
          : null !== r.match(/bidubrowser/)
          ? ((e.type = 'baidu'), i.push(/bidubrowser\/([\d.]+)/))
          : null !== r.match(/edga/)
          ? ((e.type = 'edge'), i.push(/edga\/([\d.]+)/))
          : null !== r.match(/edgios/)
          ? ((e.type = 'edge'), i.push(/edgios\/([\d.]+)/))
          : null !== r.match(/liebaofast/)
          ? ((e.type = 'liebao'), i.push(/liebaofast\/([\d.]+)/))
          : null !== r.match(/sogoumobilebrowser/)
          ? ((e.type = 'sogou'), i.push(/sogoumobilebrowser\/([\d.]+)/))
          : null !== r.match(/lbbrowser/)
          ? ((e.type = 'liebao'), i.push(/lbbrowser\/([\d.]+)/))
          : null !== r.match(/crios/)
          ? ((e.type = 'chrome'), i.push(/crios\/([\d.]+)/))
          : null !== r.match(/qihoobrowser/)
          ? ((e.type = '360'), i.push(/qihoobrowser\/([\d.]+)/))
          : null !== r.match(/mxios/)
          ? ((e.type = 'maxthon'), i.push(/mxios\/([\d.]+)/))
          : null !== r.match(/fxios/)
          ? ((e.type = 'firefox'), i.push(/fxios\/([\d.\w]+)/))
          : null !== r.match(/edge/)
          ? ((e.type = 'edge'), i.push(/edge\/([\d.]+)/))
          : null !== r.match(/metasr/)
          ? ((e.type = 'sogou'), i.push(/metasr ([\d.]+)/))
          : null !== r.match(/micromessenger/)
          ? ((e.type = 'micromessenger'), i.push(/micromessenger\/([\d.]+)/))
          : null !== r.match(/mqqbrowser/)
          ? ((e.type = 'qq'), i.push(/mqqbrowser\/([\d.]+)/))
          : null !== r.match(/qqbrowserlite/)
          ? ((e.type = 'qq'), i.push(/qqbrowserlite\/([\d.]+)/))
          : null !== r.match(/tencenttraveler/)
          ? ((e.type = 'qq'), i.push(/tencenttraveler\/([\d.]+)/))
          : null !== r.match(/qqbrowser/)
          ? ((e.type = 'qq'), i.push(/qqbrowser\/([\d.]+)/))
          : null !== r.match(/maxthon/)
          ? ((e.type = 'maxthon'), i.push(/maxthon\/([\d.]+)/))
          : null !== r.match(/ubrowser/)
          ? ((e.type = 'uc'), i.push(/ubrowser\/([\d.]+)/))
          : null !== r.match(/ucbrowser/)
          ? ((e.type = 'uc'), i.push(/ucbrowser\/([\d.]+)/))
          : null !== r.match(/firefox/)
          ? ((e.type = 'firefox'), i.push(/firefox\/([\d.]+)/))
          : null !== r.match(/opera/)
          ? ((e.type = 'opera'), i.push(/opera\/([\d.]+)/))
          : null !== r.match(/opr/)
          ? ((e.type = 'opera'), i.push(/opr\/([\d.]+)/))
          : null !== r.match(/chrome/)
          ? ((e.type = 'chrome'), i.push(/chrome\/([\d.]+)/))
          : null !== r.match(/safari/)
          ? ((e.type = 'safari'), i.push(/version\/([\d.]+)/))
          : (null === r.match(/trident/) && null === r.match(/msie/)) || (e.type = 'ie'),
          'ie' === e.type
            ? ((t = r.match(/trident\/([\d.]+)/) ? r.match(/trident\/([\d.]+)/)[1] : ''),
              (n = r.match(/msie ([\d.]+)/) ? r.match(/msie ([\d.]+)/)[1] : ''),
              '' !== t ? (e.version = String(parseInt(t) + 4)) : '' !== n && (e.version = n))
            : i && (e.version = r.match(i[0]) ? r.match(i[0])[1] : '');
      } catch (e) {
        Log.w('getting browser info failed due to ', e);
      }
      return e;
    },
    properties: function () {
      var e = _.info.browser();
      return _.extend({
        '#os': _.info.os(),
        '#lib_version': Config.LIB_VERSION,
        '#lib': 'js',
        '#screen_height': screen.height,
        '#screen_width': screen.width,
        '#browser': e.type,
        '#browser_version': e.version,
        '#system_language': _.check.isString(navigator.languages[1])
          ? navigator.languages[1].toLowerCase()
          : '取值异常',
        '#ua': _.check.isString(navigator.userAgent) ? navigator.userAgent.toLowerCase() : '取值异常',
        '#utm': _.getUtm(),
      });
    },
    pageProperties: function () {
      var e = _.getReferrer();
      return _.stripEmptyProperties({
        '#referrer': e,
        '#referrer_host': e && _.url('hostname', e),
        '#url': location.href,
        '#url_path': location.pathname,
        '#title': document.title,
      });
    },
  }),
  (_.getUtm = function () {
    var n = {};
    return (
      _.each(utmTypes, function (e) {
        var t = _.getQueryParam(location.href, e);
        t.length && (n[e] = t);
      }),
      JSON.stringify(n)
    );
  }),
  (_.getQueryParam = function (e, t) {
    (t = t.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')), (e = _.decodeURIComponent(e));
    e = new RegExp('[\\?&]' + t + '=([^&#]*)').exec(e);
    return null === e || (e && 'string' != typeof e[1] && e[1].length) ? '' : _.decodeURIComponent(e[1]);
  }),
  (_.createString = function (e) {
    for (var t = e, n = Math.random().toString(36).substr(2); n.length < t; ) n += Math.random().toString(36).substr(2);
    return (n = n.substr(0, e));
  }),
  (_.createAesKey = function () {
    return _.createString(16);
  }),
  (_.generateEncryptyData = function (e, t) {
    if (void 0 === t) return e;
    var n = t.publicKey,
      r = t.version;
    if (void 0 === n || void 0 === r) return e;
    if ('undefined' == typeof CryptoJS || 'undefined' == typeof JSEncrypt) return e;
    t = _.createAesKey();
    try {
      var i = CryptoJS.enc.Utf8.parse(t),
        o = CryptoJS.enc.Utf8.parse(JSON.stringify(e)),
        s = CryptoJS.AES.encrypt(o, i, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString(),
        a = new JSEncrypt();
      a.setPublicKey(n);
      var c = a.encrypt(t);
      return !1 === c ? (Log.w('加密失败，返回原数据'), e) : { pkv: r, ekey: c, payload: s };
    } catch (e) {
      Log.w('加密失败，返回原数据');
    }
    return e;
  }),
  (_.paramType = function (e) {
    return Object.prototype.toString.call(e).replace('[object ', '').replace(']', '');
  }),
  (_.addEvent = function (e, t, n, r) {
    if (document.addEventListener)
      if (('Array' !== this.paramType(e) && 'HTMLCollection' !== this.paramType(e)) || !e.length || e === window)
        e.addEventListener(t, n, r);
      else for (var i = 0; i < e.length; i++) this.addEvent(e[i], t, n, r);
    else if (e.length && e !== window) for (var o = 0; o < e.length; o++) this.addEvent(e[o], t, n);
    else
      e.attachEvent('on' + t, function () {
        return n.call(e, window.event);
      });
  }),
  (_.getRandom = function () {
    return new Date().getTime() + '_' + Math.floor(1e6 * Math.random());
  }),
  (_.safeJSONParse = function (t) {
    var e = null;
    try {
      e = JSON.parse(t);
    } catch (e) {
      return t;
    }
    return e;
  }),
  (_.saveObjectVal = function (e, t) {
    _.check.isString(t) || (t = JSON.stringify(t)), _.localStorage.set(e, t);
  }),
  (_.readObjectVal = function (e) {
    e = _.localStorage.get(e);
    return e ? _.safeJSONParse(e) : null;
  }),
  (_.indexOf = function (e, t) {
    var n = e.indexOf;
    if (n) return n.call(e, t);
    for (var r = 0; r < e.length; r++) if (t === e[r]) return r;
    return -1;
  }),
  (_.hasEncrypty = function (e) {
    return !!_.check.isObject() && 'undefined' !== e.pkv && 'undefined' !== e.ekey && 'undefined' !== e.payload;
  }),
  (_.addSiteEvent = function (e, t, n, u) {
    function h(e) {
      return (
        e &&
          ((e.preventDefault = h.preventDefault), (e.stopPropagation = h.stopPropagation), (e._getPath = h._getPath)),
        e
      );
    }
    h._getPath = function () {
      var e;
      return this.path || (this.composedPath && this.composedPath()) || ((e = this.target), new r(e).getParents());
    };
    var r = function (e) {
      this.ele = e;
    };
    (h.preventDefault = function () {
      this.returnValue = !1;
    }),
      (h.stopPropagation = function () {
        this.cancelBubble = !0;
      });
    !function (e, t, n) {
      var r, i, o, s, a, c;
      void 0 === u && 'click' === t && (u = !0),
        e && e.addEventListener
          ? e.addEventListener(
              t,
              function (e) {
                (e._getPath = h._getPath), n.call(this, e);
              },
              u,
            )
          : ((i = e[(r = 'on' + t)]),
            (e[r] =
              ((o = e),
              (s = n),
              (a = i),
              (c = t),
              function (e) {
                if ((e = e || h(window.event))) {
                  e.target = e.srcElement;
                  var t,
                    n = !0;
                  return (
                    'function' == typeof a && (t = a(e)),
                    (e = s.call(o, e)),
                    'beforeunload' !== c ? !1 !== t && !1 !== e && n : void 0
                  );
                }
              })));
    }.apply(null, arguments);
  }),
  (_.getURLSearchParams = function (e) {
    for (var t = {}, n = (e = e || '').substring(1).split('&'), r = 0; r < n.length; r++) {
      var i,
        o = n[r].indexOf('=');
      -1 !== o &&
        ((i = n[r].substring(0, o)),
        (o = n[r].substring(o + 1)),
        (i = _.decodeURIComponent(i)),
        (o = _.decodeURIComponent(o)),
        (t[i] = o));
    }
    return t;
  }),
  (_.urlParse = function (e) {
    function t(e) {
      (this._fields = {
        Username: 4,
        Password: 5,
        Port: 7,
        Protocol: 2,
        Host: 6,
        Path: 8,
        URL: 0,
        QueryString: 9,
        Fragment: 10,
      }),
        (this._values = {}),
        (this._regex = /^((\w+):\/\/)?((\w+):?(\w+)?@)?([^\/\?:]+):?(\d+)?(\/?[^\?#]+)?\??([^#]+)?#?(\w*)/),
        void 0 !== e && this._parse(e);
    }
    return (
      (t.prototype.setUrl = function (e) {
        this._parse(e);
      }),
      (t.prototype._initValues = function () {
        for (var e in this._fields) this._values[e] = '';
      }),
      (t.prototype.addQueryString = function (e) {
        if ('object' !== _typeof(e)) return !1;
        var t,
          n = this._values.QueryString || '';
        for (t in e)
          n = new RegExp(t + '[^&]+').test(n)
            ? n.replace(new RegExp(t + '[^&]+'), t + '=' + e[t])
            : '&' === n.slice(-1)
            ? n + t + '=' + e[t]
            : '' === n
            ? t + '=' + e[t]
            : n + '&' + t + '=' + e[t];
        this._values.QueryString = n;
      }),
      (t.prototype.getUrl = function () {
        var e = '';
        return (
          (e += this._values.Origin),
          (e += this._values.Port ? ':' + this._values.Port : ''),
          (e += this._values.Path),
          (e += this._values.QueryString ? '?' + this._values.QueryString : ''),
          (e += this._values.Fragment ? '#' + this._values.Fragment : '')
        );
      }),
      (t.prototype._parse = function (e) {
        this._initValues(), (r = this._regex.exec(e)) || Log.i('URLParser::_parse -> Invalid URL');
        var t,
          n = e.split('#'),
          e = n[0],
          n = n.slice(1).join('#'),
          r = this._regex.exec(e);
        for (t in this._fields) void 0 !== r[this._fields[t]] && (this._values[t] = r[this._fields[t]]);
        (this._values.Hostname = this._values.Host.replace(/:\d+$/, '')),
          (this._values.Origin = this._values.Protocol + '://' + this._values.Hostname),
          (this._values.Fragment = n);
      }),
      new t(e)
    );
  }),
  (_.trim = function (e) {
    return e.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
  }),
  (_.URL = function (e) {
    var t,
      n,
      r = {};
    if (
      'function' == typeof window.URL &&
      (function () {
        try {
          return 'http://modernizr.com/' === new URL('http://modernizr.com/').href;
        } catch (e) {
          return !1;
        }
      })()
    )
      (r = new URL(e)).searchParams ||
        (r.searchParams =
          ((n = _.getURLSearchParams(r.search)),
          {
            get: function (e) {
              return n[e];
            },
          }));
    else {
      _.check.isString(e) || (e = String(e)), (e = _.trim(e));
      if (!1 === /^https?:\/\/.+/.test(e)) return void Log.w('Invalid URL');
      e = _.urlParse(e);
      (r.hash = e._values.Fragment),
        (r.host = e._values.Host ? e._values.Host + (e._values.Port ? ':' + e._values.Port : '') : ''),
        (r.href = e._values.URL),
        (r.password = e._values.Password),
        (r.pathname = e._values.Path),
        (r.port = e._values.Port),
        (r.search = e._values.QueryString ? '?' + e._values.QueryString : ''),
        (r.username = e._values.Username),
        (r.hostname = e._values.Hostname),
        (r.protocol = e._values.Protocol ? e._values.Protocol + ':' : ''),
        (r.origin = e._values.Origin ? e._values.Origin + (e._values.Port ? ':' + e._values.Port : '') : ''),
        (r.searchParams =
          ((t = _.getURLSearchParams('?' + e._values.QueryString)),
          {
            get: function (e) {
              return t[e];
            },
          }));
    }
    return r;
  });
var Log = (function () {
    function e() {
      _classCallCheck(this, e);
    }
    return (
      _createClass(e, null, [
        {
          key: 'i',
          value: function () {
            if (!this.showLog) return !1;
            if (
              ((!0 !== this.showLog && 'string' !== this.showLog) || (arguments[0] = _.formatJsonString(arguments[0])),
              'object' === ('undefined' == typeof console ? 'undefined' : _typeof(console)) && console.log)
            )
              try {
                return console.log.apply(console, arguments);
              } catch (e) {
                console.log(arguments[0]);
              }
          },
        },
        {
          key: 'w',
          value: function () {
            if (!this.showLog) return !1;
            if (
              ((!0 !== this.showLog && 'string' !== this.showLog) || (arguments[0] = _.formatJsonString(arguments[0])),
              'object' === ('undefined' == typeof console ? 'undefined' : _typeof(console)) && console.warn)
            )
              try {
                return console.warn.apply(console, arguments);
              } catch (e) {
                console.warn(arguments[0]);
              }
          },
        },
      ]),
      e
    );
  })(),
  KEY_NAME_MATCH_REGEX = /^[a-zA-Z][a-zA-Z0-9_]{0,49}$/,
  PropertyChecker = (function () {
    function e() {
      _classCallCheck(this, e);
    }
    return (
      _createClass(e, null, [
        {
          key: 'stripProperties',
          value: function (e) {
            return (
              _.check.isObject(e) &&
                _.each(e, function (e, t) {
                  _.check.isString(e) ||
                    _.check.isNumber(e) ||
                    _.check.isDate(e) ||
                    _.check.isBoolean(e) ||
                    _.check.isArray(e) ||
                    _.check.isObject(e) ||
                    Log.w(
                      '您的数据-',
                      t,
                      e,
                      '-格式不满足要求，可能无法正确入库. 属性值只支持 String, Number, Date, Boolean, Array，Object',
                    );
                }),
              e
            );
          },
        },
        {
          key: '_checkPropertiesKey',
          value: function (e) {
            var n = !0;
            return (
              _.each(e, function (e, t) {
                KEY_NAME_MATCH_REGEX.test(t) || (Log.w('不合法的 KEY 值: ' + t), (n = !1));
              }),
              n
            );
          },
        },
        {
          key: 'event',
          value: function (e) {
            return (
              !(!_.check.isString(e) || !KEY_NAME_MATCH_REGEX.test(e)) ||
              (Log.w(
                "请检查参数格式, eventName 必须是英文字母或者 '_' 开头, 包含字母和数字的不超过50个字符的字符串: " + e,
              ),
              !1)
            );
          },
        },
        {
          key: 'propertyName',
          value: function (e) {
            return (
              !(!_.check.isString(e) || !KEY_NAME_MATCH_REGEX.test(e)) ||
              (Log.w(
                "请检查参数格式, propertyName 必须是英文字母或者 '_' 开头, 包含字母和数字的不超过50个字符的字符串: " +
                  e,
              ),
              !1)
            );
          },
        },
        {
          key: 'properties',
          value: function (e) {
            return (
              this.stripProperties(e),
              !e ||
                (_.check.isObject(e)
                  ? !!this._checkPropertiesKey(e) ||
                    (Log.w(
                      '请检查参数格式, properties 的 key 只能以字母开头，包含数字、字母和下划线 _，长度最大为50个字符',
                    ),
                    !1)
                  : (Log.w('properties 可以没有，但有的话必须是对象'), !1))
            );
          },
        },
        {
          key: 'propertiesMust',
          value: function (e) {
            return (
              this.stripProperties(e),
              void 0 === e || !_.check.isObject(e) || _.check.isEmptyObject(e)
                ? (Log.w('properties必须是对象且有值'), !1)
                : !!this._checkPropertiesKey(e) ||
                  (Log.w(
                    '请检查参数格式, properties 的 key 只能以字母开头，包含数字、字母和下划线 _，长度最大为50个字符',
                  ),
                  !1)
            );
          },
        },
        {
          key: 'userId',
          value: function (e) {
            return (
              !(!_.check.isString(e) || !/^.{1,63}$/.test(e)) ||
              (Log.w('用户 id 必须是不能为空，且小于 64 位的字符串'), !1)
            );
          },
        },
      ]),
      e
    );
  })(),
  MASTER_INSTANCE_NAME = 'thinkingdata',
  DEFAULT_CONFIG = {
    _name: MASTER_INSTANCE_NAME,
    appId: '',
    send_method: 'image',
    persistence: 'localStorage',
    persistencePrefix: 'ThinkingDataJSSDK',
    persistenceEnabled: !0,
    crossSubDomain: !0,
    maxReferrerStringLength: 200,
    showLog: !0,
    dataSendTimeout: 3e3,
    useAppTrack: !1,
    strict: !1,
    tryCount: 3,
  },
  ThinkingDataPersistence = function (e) {
    (this._state = {}), (this.crossSubDomain = e.crossSubDomain), (this.enabled = e.persistenceEnabled);
    var t,
      n = null;
    this.enabled &&
      (!1 === e.crossSubDomain
        ? ((t = _.url('sub', location.href)),
          (this.name =
            'string' == typeof t && '' !== t ? e.persistencePrefix + '_' + t : e.persistencePrefix + '_root'))
        : (this.name = e.persistencePrefix + '_cross'),
      'cookie' !== (t = e.persistence) &&
        'localStorage' !== t &&
        (Log.i('Unknown persistence type ' + t + '; falling back to cookie'), (t = e.persistence = 'cookie')),
      'localStorage' === t && _.localStorage.isSupported()
        ? ((this.storage = _.localStorage),
          (n = _.cookie.get(this.name)) && _.cookie.remove(this.name, this.crossSubDomain))
        : (Log.i('localStorage is not support by the browser; falling back to cookie'), (this.storage = _.cookie))),
      this._load(n),
      this.getDistinctId() || ((e = e.uuid || _.UUID()), this._setDeviceId(e), this.setDistinctId(e));
  };
(ThinkingDataPersistence.prototype._load = function (e) {
  var t;
  this.enabled &&
    (null !== (t = null !== e ? e : this.storage.get(this.name)) &&
      _.check.isJSONString(t) &&
      (this._state = _.extend({}, JSON.parse(t))),
    null !== e && this._save());
}),
  (ThinkingDataPersistence.prototype.getDistinctId = function () {
    return this._state.distinct_id;
  }),
  (ThinkingDataPersistence.prototype.setDistinctId = function (e) {
    this._set('distinct_id', e);
  }),
  (ThinkingDataPersistence.prototype.setEnableTracking = function (e) {
    this._set('enable_tracking', e);
  }),
  (ThinkingDataPersistence.prototype.getEnableTracking = function () {
    return !!_.check.isUndefined(this._state.enable_tracking) || this._state.enable_tracking;
  }),
  (ThinkingDataPersistence.prototype.clear = function () {
    (this._state = {}), this._save();
  }),
  (ThinkingDataPersistence.prototype.setOptTracking = function (e) {
    this._set('opt_tracking', e);
  }),
  (ThinkingDataPersistence.prototype.getOptTracking = function () {
    return !!_.check.isUndefined(this._state.opt_tracking) || this._state.opt_tracking;
  }),
  (ThinkingDataPersistence.prototype.setDistinctId = function (e) {
    this._set('distinct_id', e);
  }),
  (ThinkingDataPersistence.prototype.getAccountId = function () {
    return this._state.account_id;
  }),
  (ThinkingDataPersistence.prototype.setAccountId = function (e) {
    this._set('account_id', e);
  }),
  (ThinkingDataPersistence.prototype.getDeviceId = function () {
    return this._state.device_id;
  }),
  (ThinkingDataPersistence.prototype.setSuperProperties = function (e) {
    this._set('super_properties', e);
  }),
  (ThinkingDataPersistence.prototype.getSuperProperties = function () {
    return this._state.super_properties || {};
  }),
  (ThinkingDataPersistence.prototype.setEventTimer = function (e, t) {
    var n = this._state.event_timers || {};
    (n[e] = t), this._set('event_timers', n);
  }),
  (ThinkingDataPersistence.prototype.clearEventTimer = function () {
    this._set('event_timers', {});
  }),
  (ThinkingDataPersistence.prototype.removeEventTimer = function (e) {
    var t = (this._state.event_timers || {})[e];
    return _.check.isUndefined(t) || (delete this._state.event_timers[e], this._save()), t;
  }),
  (ThinkingDataPersistence.prototype._setDeviceId = function (e) {
    this._state.device_id
      ? Log.w('Current device_id is ', this.getDeviceId(), ", it couldn't been set to: ", e)
      : this._set('device_id', e);
  }),
  (ThinkingDataPersistence.prototype._save = function () {
    this.enabled && this.storage.set(this.name, JSON.stringify(this._state), 73e3, this.crossSubDomain);
  }),
  (ThinkingDataPersistence.prototype._set = function (e, t) {
    (this._state = this._state || {}), (this._state[e] = t), this._save();
  });
var ThinkingDataAnalyticsLib = function () {};
(ThinkingDataAnalyticsLib.prototype.trackLink = function (e, n, r) {
  var i,
    o,
    s = this;
  this._isCollectData() &&
    ((i = this._getConfig('strict')),
    PropertyChecker.properties(r) || !i
      ? e &&
        _.check.isObject(e) &&
        ((o = []),
        _.each(e, function (e, n) {
          e &&
            _.check.isArray(e) &&
            _.each(e, function (e) {
              switch (n) {
                case 'tag':
                  _.each(document.getElementsByTagName(e), function (e) {
                    o.indexOf(e) < 0 && o.push(e);
                  });
                  break;
                case 'class':
                  _.each(document.getElementsByClassName(e), function (e) {
                    o.indexOf(e) < 0 && o.push(e);
                  });
                  break;
                case 'id':
                  var t = document.getElementById(e);
                  null !== t && o.indexOf(t) < 0 && o.push(t);
              }
            });
        }),
        _.each(o, function (e) {
          var t;
          null !== e &&
            (((t = _.extend({}, _.info.pageProperties(), r))['#element_type'] = e.nodeName.toLowerCase()),
            _.check.isUndefined(t.name) &&
              (t.name = e.getAttribute('td-name') || e.innerHTML || e.value || '未获取标识'),
            e.addEventListener('click', function () {
              s._sendRequest({ type: 'track', event: n, properties: i ? PropertyChecker.stripProperties(t) : t });
            }));
        }))
      : Log.w('trackLink failed due to invalid properties.'));
}),
  (ThinkingDataAnalyticsLib.prototype.setPageProperty = function (e) {
    this._isCollectData() &&
      (PropertyChecker.properties(e) || !this._getConfig('strict')
        ? _.extend(this.currentProps, e)
        : Log.w('PageProperty 输入的参数有误'));
  }),
  (ThinkingDataAnalyticsLib.prototype.getPageProperty = function () {
    return this.currentProps;
  }),
  (ThinkingDataAnalyticsLib.prototype.getPresetProperties = function () {
    var e = _.info.properties(),
      t = {};
    (t.os = e['#os']),
      (t.screenWidth = e['#screen_width']),
      (t.screenHeight = e['#screen_height']),
      (t.browser = e['#browser']),
      (t.browserVersion = e['#browser_version']),
      (t.deviceId = this.getDeviceId());
    e = 0 - new Date().getTimezoneOffset() / 60;
    return (
      this._getConfig('zoneOffset') && (e = this._getConfig('zoneOffset')),
      (t.zoneOffset = e),
      (t.toEventPresetProperties = function () {
        return {
          '#os': t.os,
          '#screen_width': t.screenWidth,
          '#screen_height': t.screenHeight,
          '#browser': t.browser,
          '#browser_version': t.browserVersion,
          '#device_id': t.deviceId,
          '#zone_offset': t.zoneOffset,
        };
      }),
      t
    );
  }),
  (ThinkingDataAnalyticsLib.prototype.login = function (e) {
    this._isCollectData() &&
      ('number' == typeof e && (e = String(e)),
      PropertyChecker.userId(e) || !this._getConfig('strict')
        ? e !== this.persistence.getAccountId() && this.persistence.setAccountId(e)
        : Log.e('login 的参数必须是字符串'));
  }),
  (ThinkingDataAnalyticsLib.prototype.logout = function (e) {
    this._isCollectData() &&
      (!0 === e && ((e = _.UUID()), this.persistence.setDistinctId(e)), this.persistence.setAccountId(''));
  }),
  (ThinkingDataAnalyticsLib.prototype.userSet = function (e, t) {
    this._isCollectData() &&
      ((!PropertyChecker.propertiesMust(e) && this._getConfig('strict')) ||
        this._sendRequest({ type: 'user_set', properties: e }, t));
  }),
  (ThinkingDataAnalyticsLib.prototype.userSetOnce = function (e, t) {
    this._isCollectData() &&
      ((!PropertyChecker.propertiesMust(e) && this._getConfig('strict')) ||
        this._sendRequest({ type: 'user_setOnce', properties: e }, t));
  }),
  (ThinkingDataAnalyticsLib.prototype.userUnset = function (e, t) {
    var n;
    this._isCollectData() &&
      ((!PropertyChecker.propertyName(e) && this._getConfig('strict')) ||
        (((n = {})[e] = 0), this._sendRequest({ type: 'user_unset', properties: n }, t)));
  }),
  (ThinkingDataAnalyticsLib.prototype.userAdd = function (e, t) {
    var n;
    this._isCollectData() &&
      (_.check.isString(e) && ((n = e), ((e = {})[n] = 1)),
      PropertyChecker.propertiesMust(e) &&
        (!(function (e) {
          for (var t in e) if (!/-*\d+/.test(String(e[t]))) return;
          return 1;
        })(e) && this._getConfig('strict')
          ? Log.w('userAdd 属性中的值只能是数字')
          : this._sendRequest({ type: 'user_add', properties: e }, t)));
  }),
  (ThinkingDataAnalyticsLib.prototype.userAppend = function (e, t) {
    this._isCollectData() &&
      ((PropertyChecker.propertiesMust(e) &&
        (function (e) {
          for (var t in e) if (!_.check.isArray(e[t])) return;
          return 1;
        })(e)) ||
      !this._getConfig('strict')
        ? this._sendRequest({ type: 'user_append', properties: e }, t)
        : Log.w('userAppend 属性中的值只能是 Array'));
  }),
  (ThinkingDataAnalyticsLib.prototype.userUniqAppend = function (e, t) {
    this._isCollectData() &&
      ((PropertyChecker.propertiesMust(e) &&
        (function (e) {
          for (var t in e) if (!_.check.isArray(e[t])) return;
          return 1;
        })(e)) ||
      !this._getConfig('strict')
        ? this._sendRequest({ type: 'user_uniq_append', properties: e }, t)
        : Log.w('userUniqAppend 属性中的值只能是 Array'));
  }),
  (ThinkingDataAnalyticsLib.prototype.flush = function () {
    this.batchConsumer && !this._isDebug() && this.batchConsumer.flush();
  }),
  (ThinkingDataAnalyticsLib.prototype.userDel = function (e) {
    this._isCollectData() && this._sendRequest({ type: 'user_del' }, e);
  }),
  (ThinkingDataAnalyticsLib.prototype._sendRequest = function (e, t, n) {
    var r,
      i = _.check.isUndefined(e.time) || !_.check.isDate(e.time) ? new Date() : e.time,
      o = {
        data: [
          {
            '#type': e.type,
            '#time': _.formatDate(_.formatTimeZone(i, this._getConfig('zoneOffset'))),
            '#distinct_id': this.persistence.getDistinctId(),
          },
        ],
      };
    if (
      (this.persistence.getAccountId() && (o.data[0]['#account_id'] = this.persistence.getAccountId()),
      'track' === e.type || 'track_update' === e.type || 'track_overwrite' === e.type
        ? ((o.data[0]['#event_name'] = e.event),
          'track_update' === e.type || 'track_overwrite' === e.type
            ? (o.data[0]['#event_id'] = e.extraId)
            : e.firstCheckId && (o.data[0]['#first_check_id'] = e.firstCheckId),
          (i = 0 - i.getTimezoneOffset() / 60),
          this._getConfig('zoneOffset') && (i = this._getConfig('zoneOffset')),
          (o.data[0].properties = _.extend(
            {},
            { '#device_id': this.persistence.getDeviceId(), '#zone_offset': i },
            _.info.properties(),
            this.getSuperProperties(),
            this.dynamicProperties ? this.dynamicProperties() : {},
            this.getPageProperty(),
          )),
          (i = this.persistence.removeEventTimer(e.event)),
          _.check.isUndefined(i) ||
            ((i = new Date().getTime() - i),
            (i = parseFloat((i / 1e3).toFixed(3))),
            (o.data[0].properties['#duration'] = i = 86400 < i ? 86400 : i)))
        : (o.data[0].properties = {}),
      _.check.isObject(e.properties) &&
        !_.check.isEmptyObject(e.properties) &&
        _.extend(o.data[0].properties, e.properties),
      _.searchObjDate(o.data[0], this._getConfig('zoneOffset')),
      'ajax' === this._getConfig('send_method') &&
        (o.data[0] = _.generateEncryptyData(o.data[0], this._getConfig('secretKey'))),
      (o['#app_id'] = this._getConfig('appId')),
      (o['#flush_time'] = _.formatTimeZone(new Date(), this._getConfig('zoneOffset')).getTime()),
      Log.i(o),
      this._getConfig('useAppTrack'))
    ) {
      var s = window.ThinkingData_APP_JS_Bridge || {};
      if ('object' === _typeof(s) && s.thinkingdata_track)
        return s.thinkingdata_track(JSON.stringify(o)), void ('function' == typeof t && t());
      if (/td-sdk-ios/.test(navigator.userAgent) && !window.MSStream) {
        var a = document.createElement('iframe');
        return (
          a.setAttribute('src', 'thinkinganalytics://trackEvent?event=' + _.encodeURIComponent(JSON.stringify(o))),
          document.documentElement.appendChild(a),
          a.parentNode.removeChild(a),
          (a = null),
          void ('function' == typeof t && t())
        );
      }
    }
    if ((n && (o.data[0]['#uuid'] = _.UUIDv4()), !this.batchConsumer || this._isDebug() || n)) {
      if (
        (this._isDebug()
          ? ((r =
              '&data=' +
              _.encodeURIComponent(JSON.stringify(o.data[0])) +
              '&source=client&deviceId=' +
              this.getDeviceId() +
              '&appid=' +
              this._getConfig('appId') +
              '&version=' +
              Config.LIB_VERSION),
            'debug_only' === this._getConfig('mode') && (r += '&dryRun=1'))
          : ((o = JSON.stringify(o)),
            (s = _.base64Encode(o)),
            (a = 'crc=' + _.hashCode(s)),
            (r =
              '&data=' +
              _.encodeURIComponent(s) +
              '&ext=' +
              _.encodeURIComponent(a) +
              '&version=' +
              Config.LIB_VERSION)),
        n && void 0 !== ('undefined' == typeof navigator ? 'undefined' : _typeof(navigator)) && navigator.sendBeacon)
      ) {
        n = new FormData();
        return (
          this._isDebug()
            ? (n.append('data', _.encodeURIComponent(JSON.stringify(o.data[0]))),
              n.append('source', 'client'),
              n.append('deviceId', this.getDeviceId()),
              n.append('appid', this._getConfig('appId')),
              n.append('version', Config.LIB_VERSION),
              'debug_only' === this._getConfig('mode') && n.append('dryRun', 1))
            : n.append('data', _.base64Encode(o)),
          void navigator.sendBeacon(this._getConfig('serverUrl'), n)
        );
      }
      'ajax' === this._getConfig('send_method')
        ? new AjaxTask(r, this._getConfig('serverUrl'), this._getConfig('tryCount'), t, this._isDebug()).run()
        : this._sendRequestWithImage(r, t);
    } else this.batchConsumer.add(o.data[0]);
  });
var dataStoragePrefix = 'ta_',
  tabStoragePrefix = 'tab_';
function BatchConsumer(e) {
  (this.config = e),
    (this.timer = null),
    (this.batchConfig = _.extend({ size: 5, interval: 5e3, storageLimit: 200 }, this.config.batch)),
    this.batchConfig.size < 1 && (this.batchConfig.size = 1),
    30 < this.batchConfig.size && (this.batchConfig.size = 30),
    (this.prefix = this.config.persistencePrefix),
    (this.tabKey = this.prefix + tabStoragePrefix + this.config.appId),
    (this.storageLimit = this.batchConfig.storageLimit);
}
BatchConsumer.prototype = {
  batchInterval: function () {
    var e = this;
    e.timer = setTimeout(function () {
      e.recycle(), e.send(), clearTimeout(e.timer), e.batchInterval();
    }, this.batchConfig.interval);
  },
  add: function (e) {
    var t = e,
      n = this.prefix + dataStoragePrefix + this.config.appId + '_' + String(_.getRandom()),
      e = _.localStorage.get(this.tabKey);
    if ((e = (null !== e && _.safeJSONParse(e)) || []).length <= this.storageLimit)
      e.push(n), _.localStorage.set(this.tabKey, JSON.stringify(e)), _.saveObjectVal(n, t);
    else {
      var r = e.splice(0, 20);
      console.log('删除的数据:' + r),
        e.push(n),
        _.localStorage.set(this.tabKey, JSON.stringify(e)),
        _.saveObjectVal(n, t);
      for (var t = {}, i = [], o = 0; o < r.length; o++) {
        var s = _.readObjectVal(r[o]);
        _.hasEncrypty(s) || (s = _.generateEncryptyData(s, this.config.secretKey)), i.push(s);
      }
      (t.data = i),
        (t['#app_id'] = this.config.appId),
        (t['#flush_time'] = _.formatTimeZone(new Date(), this.config.zoneOffset).getTime()),
        this.request(t, r);
    }
  },
  flush: function () {
    clearTimeout(this.timer), this.send(), this.batchInterval();
  },
  send: function () {
    var e = _.localStorage.get(this.tabKey);
    if (e && (e = _.safeJSONParse(e) || []).length) {
      for (
        var t = {}, n = [], r = [], i = e.length < this.batchConfig.size ? e.length : this.batchConfig.size, o = 0;
        o < i;
        o++
      ) {
        var s = _.readObjectVal(e[o]);
        _.hasEncrypty(s) || (s = _.generateEncryptyData(s, this.config.secretKey)), n.push(s), r.push(e[o]);
      }
      (t.data = n),
        (t['#app_id'] = this.config.appId),
        (t['#flush_time'] = _.formatTimeZone(new Date(), this.config.zoneOffset).getTime()),
        this.request(t, r);
    }
  },
  request: function (e, t) {
    var n = this;
    Log.i('flush data'), Log.i(e);
    var r = JSON.stringify(e),
      e = _.base64Encode(r),
      r = 'crc=' + _.hashCode(e),
      r = '&data=' + _.encodeURIComponent(e) + '&ext=' + _.encodeURIComponent(r) + '&version=' + Config.LIB_VERSION;
    new AjaxTask(
      r,
      this.config.serverUrl,
      this.config.tryCount,
      function () {
        n.remove(t);
      },
      !1,
    ).run();
  },
  remove: function (e) {
    var t = _.localStorage.get(this.tabKey);
    if (t) {
      for (var n = _.safeJSONParse(t) || [], r = 0; r < e.length; r++) {
        var i = _.indexOf(n, e[r]);
        -1 < i && n.splice(i, 1), _.localStorage.remove(e[r]);
      }
      _.localStorage.set(this.tabKey, JSON.stringify(n));
    }
  },
  recycle: function () {
    var e = _.localStorage.get(this.tabKey);
    if (e && 0 === (e = _.safeJSONParse(e) || []).length) {
      for (var t = 0; t < localStorage.length; t++) {
        var n = localStorage.key(t);
        0 === n.indexOf(this.prefix + dataStoragePrefix + this.config.appId) && e.push(n);
      }
      0 < e.length && _.localStorage.set(this.tabKey, JSON.stringify(e));
    }
  },
};
var AjaxTask = (function () {
  function o(e, t, n, r, i) {
    _classCallCheck(this, o),
      (this.data = e),
      (this.serverUrl = t),
      (this.tryCount = n || 3),
      (this.callback = r),
      (this.isDebug = i);
  }
  return (
    _createClass(o, [
      {
        key: 'run',
        value: function () {
          var t = null;
          (t = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP')).open(
            'post',
            this.serverUrl,
            !0,
          ),
            t.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
          var n = this;
          (t.onreadystatechange = function () {
            var e;
            4 === t.readyState &&
              ((200 <= t.status && t.status < 300) || 304 === t.status
                ? (n.callback && n.callback(),
                  n.isDebug && (0 !== (e = JSON.parse(t.response)).errorLevel ? Log.w(e) : Log.i(e)))
                : n.onFailed());
          }),
            t.send(this.data);
        },
      },
      {
        key: 'onFailed',
        value: function () {
          0 < --this.tryCount && this.run();
        },
      },
    ]),
    o
  );
})();
(ThinkingDataAnalyticsLib.prototype._isDebug = function () {
  return 'debug' === this._getConfig('mode') || 'debug_only' === this._getConfig('mode');
}),
  (ThinkingDataAnalyticsLib.prototype._sendRequestWithImage = function (e, t) {
    function n(e) {
      e && !e.hasCalled && ((e.hasCalled = !0), e.callback && e.callback());
    }
    var r =
        (-1 !== this._getConfig('serverUrl').indexOf('?')
          ? this._getConfig('serverUrl')
          : this._getConfig('serverUrl') + '?') + e,
      e = document.createElement('img');
    (e.callback = t),
      setTimeout(n, this._getConfig('dataSendTimeout'), e),
      (e.onload = function () {
        (this.onload = null), n(this);
      }),
      (e.onerror = function () {
        (this.onerror = null), n(this);
      }),
      (e.onabort = function () {
        (this.onabort = null), n(this);
      }),
      (e.src = r);
  }),
  (ThinkingDataAnalyticsLib.prototype.track = function (e, t, n, r) {
    this._isCollectData() &&
      ('ta_page_show' === e ||
        'ta_page_hide' === e ||
        (PropertyChecker.event(e) && PropertyChecker.properties(t)) ||
        !this._getConfig('strict')) &&
      this._sendRequest({ type: 'track', event: e, time: _.check.isDate(n) ? n : new Date(), properties: t }, r);
  }),
  (ThinkingDataAnalyticsLib.prototype.trackUpdate = function (e) {
    this._isCollectData() &&
      (_.check.isObject(e)
        ? ((PropertyChecker.event(e.eventName) && PropertyChecker.properties(e.properties)) ||
            !this._getConfig('strict')) &&
          this._sendRequest(
            {
              type: 'track_update',
              event: e.eventName,
              time: _.check.isDate(e.eventTime) ? e.eventTime : new Date(),
              properties: e.properties,
              extraId: e.eventId,
            },
            e.callback,
          )
        : Log.e('trackUpdate 参数不符合要求'));
  }),
  (ThinkingDataAnalyticsLib.prototype.trackOverwrite = function (e) {
    this._isCollectData() &&
      (_.check.isObject(e)
        ? ((PropertyChecker.event(e.eventName) && PropertyChecker.properties(e.properties)) ||
            !this._getConfig('strict')) &&
          this._sendRequest(
            {
              type: 'track_overwrite',
              event: e.eventName,
              time: _.check.isDate(e.eventTime) ? e.eventTime : new Date(),
              properties: e.properties,
              extraId: e.eventId,
            },
            e.callback,
          )
        : Log.e('trackOverwrite 参数不符合要求'));
  }),
  (ThinkingDataAnalyticsLib.prototype.trackFirstEvent = function (e) {
    this._isCollectData() &&
      (_.check.isObject(e)
        ? ((PropertyChecker.event(e.eventName) && PropertyChecker.properties(e.properties)) ||
            !this._getConfig('strict')) &&
          this._sendRequest(
            {
              type: 'track',
              event: e.eventName,
              time: _.check.isDate(e.eventTime) ? e.eventTime : new Date(),
              properties: e.properties,
              firstCheckId: e.firstCheckId || this.getDeviceId(),
            },
            e.callback,
          )
        : Log.e('trackFirstEvent 参数不符合要求'));
  }),
  (ThinkingDataAnalyticsLib.prototype.trackWithBeacon = function (e, t, n, r) {
    ('ta_page_hide' === e ||
      (PropertyChecker.event(e) && PropertyChecker.properties(t)) ||
      !this._getConfig('strict')) &&
      this._sendRequest({ type: 'track', event: e, time: _.check.isDate(n) ? n : new Date(), properties: t }, r, !0);
  }),
  (ThinkingDataAnalyticsLib.prototype.identify = function (e) {
    this._isCollectData() &&
      ('number' == typeof e && (e = String(e)),
      PropertyChecker.userId(e) || !this._getConfig('strict')
        ? e !== this.persistence.getDistinctId() && this.persistence.setDistinctId(e)
        : Log.e('identify 的参数必须是字符串'));
  }),
  (ThinkingDataAnalyticsLib.prototype.getDistinctId = function () {
    return this.persistence.getDistinctId();
  }),
  (ThinkingDataAnalyticsLib.prototype.getDeviceId = function () {
    return this.persistence.getDeviceId();
  }),
  (ThinkingDataAnalyticsLib.prototype._isCollectData = function () {
    return this.persistence.getOptTracking() && this.persistence.getEnableTracking();
  }),
  (ThinkingDataAnalyticsLib.prototype.setSuperProperties = function (e) {
    this._isCollectData() &&
      (PropertyChecker.propertiesMust(e) || !this._getConfig('strict')
        ? this.persistence.setSuperProperties(_.extend({}, this.getSuperProperties(), e))
        : Log.w('setSuperProperties 参数不合法'));
  }),
  (ThinkingDataAnalyticsLib.prototype.getSuperProperties = function () {
    return this.persistence.getSuperProperties();
  }),
  (ThinkingDataAnalyticsLib.prototype.clearSuperProperties = function () {
    this._isCollectData() && this.persistence.setSuperProperties({});
  }),
  (ThinkingDataAnalyticsLib.prototype.unsetSuperProperty = function (e) {
    var t;
    this._isCollectData() &&
      _.check.isString(e) &&
      (delete (t = this.getSuperProperties())[e], this.persistence.setSuperProperties(t));
  }),
  (ThinkingDataAnalyticsLib.prototype.setDynamicSuperProperties = function (e) {
    this._isCollectData() &&
      ('function' == typeof e
        ? PropertyChecker.properties(e()) || !this._getConfig('strict')
          ? (this.dynamicProperties = e)
          : Log.w('动态公共属性必须返回合法的属性值')
        : Log.w('setDynamicSuperProperties 的参数必须是 function 类型'));
  }),
  (ThinkingDataAnalyticsLib.prototype.timeEvent = function (e) {
    this._isCollectData() &&
      (_.check.isUndefined(e)
        ? Log.w('No event name provided to timeEvent')
        : this.persistence.setEventTimer(e, new Date().getTime()));
  }),
  (ThinkingDataAnalyticsLib.prototype.quick = function (e, t) {
    var n;
    this._isCollectData() &&
      ('string' == typeof e && 'autoTrack' === e
        ? ((n = {}),
          PropertyChecker.properties(t) && _.extend(n, t),
          this._sendRequest({ type: 'track', event: 'ta_pageview', properties: _.extend(n, _.info.pageProperties()) }))
        : 'string' == typeof e && 'siteLinker' === e
        ? siteLinker.init(this, t)
        : Log.w('quick方法中没有这个功能' + e));
  }),
  (ThinkingDataAnalyticsLib.prototype._setConfig = function (e) {
    _.check.isObject(e) &&
      (_.extend(this.config, e),
      this._getConfig('persistencePrefix') || (this.config.persistencePrefix = this.config.cookiePrefix),
      this.persistence);
  }),
  (ThinkingDataAnalyticsLib.prototype._getConfig = function (e) {
    return this.config[e];
  }),
  (ThinkingDataAnalyticsLib.prototype.init = function (e) {
    var t;
    _.check.isUndefined(this.config)
      ? ((this.config = {}),
        (this.currentProps = this.currentProps || {}),
        this._setConfig(_.extend({}, DEFAULT_CONFIG, e)),
        (this.persistence = new ThinkingDataPersistence(this.config)),
        (t = this._getConfig('appId')),
        _.check.isUndefined(t) || this._setConfig({ appId: t.replace(/\s*/g, '') }),
        (Log.showLog = this._getConfig('showLog')),
        'image' !== (t = this._getConfig('send_method')) &&
          'ajax' !== t &&
          'beacon' !== t &&
          (Log.i('send_method', t, 'is not supported. Changed to image as default value'),
          this._setConfig({ send_method: 'image' })),
        new PageLifeCycle(this, this._getConfig('autoTrack')).start(),
        this._isDebug()
          ? this._setConfig({ serverUrl: _.url('basic', e.serverUrl) + '/data_debug' })
          : this._setConfig({ serverUrl: _.url('basic', e.serverUrl) + '/sync_js' }),
        void 0 !== this._getConfig('batch') &&
          !1 !== this._getConfig('batch') &&
          _.localStorage.isSupported() &&
          ((this.batchConsumer = new BatchConsumer(this.config)), this.batchConsumer.batchInterval()))
      : Log.i('The ThinkingData libraray has been initialized.');
  });
var PageLifeCycle = (function () {
  function n(e, t) {
    _classCallCheck(this, n),
      (this.taLib = e),
      'Object' === _.paramType(t) && 'Boolean' === _.paramType(t.pageShow)
        ? (this.autoPageShow = t.pageShow)
        : (this.autoPageShow = !1),
      'Object' === _.paramType(t) && 'Boolean' === _.paramType(t.pageHide)
        ? (this.autoPageHide = t.pageHide)
        : (this.autoPageHide = !1);
  }
  return (
    _createClass(n, [
      {
        key: 'start',
        value: function () {
          var e = this;
          'onpageShow' in window
            ? (_.addEvent(window, 'pageShow', function () {
                e.trackPageShowEvent();
              }),
              _.addEvent(window, 'pagehide', function () {}))
            : (_.addEvent(window, 'load', function () {
                e.trackPageShowEvent();
              }),
              _.addEvent(window, 'beforeunload', function () {})),
            'onvisibilitychange' in document &&
              _.addEvent(document, 'visibilitychange', function () {
                document.hidden ? e.trackPageHideEvent() : e.trackPageShowEvent();
              });
        },
      },
      {
        key: 'trackPageShowEvent',
        value: function () {
          this.autoPageShow && this.taLib.track('ta_page_show', _.info.pageProperties()),
            this.taLib.timeEvent('ta_page_hide');
        },
      },
      {
        key: 'trackPageHideEvent',
        value: function () {
          this.autoPageHide && this.taLib.trackWithBeacon('ta_page_hide', _.info.pageProperties());
        },
      },
      {
        key: 'trackPageHideEventOnClose',
        value: function () {
          this.autoPageHide && this.isPageShow && this.taLib.trackWithBeacon('ta_page_hide', _.info.pageProperties());
        },
      },
    ]),
    n
  );
})();
(ThinkingDataAnalyticsLib.prototype.initInstance = function (e, t) {
  if (!_.check.isString(e) || (!_.check.isUndefined(t) && !_.check.isObject(t)))
    return Log.w('invalid parameter of initInstance(string, object).'), null;
  if (this._getConfig('_name') !== MASTER_INSTANCE_NAME)
    return Log.w('This function is allowed for master instance only'), null;
  if (e === MASTER_INSTANCE_NAME || this[e])
    return Log.w('The name ', e, " couldn't be used for create new instance."), null;
  _.check.isUndefined(t) && (t = {});
  var n = new ThinkingDataAnalyticsLib(),
    t = _.extend({}, this.config, { _name: e, persistenceEnabled: !1, uuid: this.getDeviceId() }, t);
  t.persistenceEnabled && (t.persistencePrefix = t.persistencePrefix + '_' + e), n.init(t), (this[e] = n);
}),
  (ThinkingDataAnalyticsLib.prototype.enableTracking = function (e) {
    'boolean' == typeof e && this.persistence.setEnableTracking(e);
  }),
  (ThinkingDataAnalyticsLib.prototype.optOutTracking = function () {
    this.persistence.setSuperProperties({}),
      this.persistence.setAccountId(''),
      this.persistence.clearEventTimer(),
      this.persistence.setOptTracking(!1);
  }),
  (ThinkingDataAnalyticsLib.prototype.optInTracking = function () {
    this.persistence.setOptTracking(!0);
  }),
  (ThinkingDataAnalyticsLib.prototype.setTrackStatus = function (e) {
    _.check.isObject(e) &&
      ('pause' === (e = e.status)
        ? this.enableTracking(!1)
        : 'stop' === e
        ? this.optOutTracking()
        : 'normal' === e && (this.enableTracking(!0), this.optInTracking()));
  });
var siteLinker = {},
  tdMaster;
function initAsModule() {
  return (tdMaster = new ThinkingDataAnalyticsLib());
}
(siteLinker.getPartUrl = function (e) {
  var t = this.option.length;
  if (t) for (var n = 0; n < t; n++) if (-1 < e.indexOf(this.option[n].part_url)) return !0;
  return !1;
}),
  (siteLinker.getPartHash = function (e) {
    var t = this.option.length;
    if (t) for (var n = 0; n < t; n++) if (-1 < e.indexOf(this.option[n].part_url)) return this.option[n].after_hash;
    return !1;
  }),
  (siteLinker.getCurrenId = function () {
    var e = this.ta.getDistinctId();
    return encodeURIComponent('d' + e);
  }),
  (siteLinker.rewriteUrl = function (e, t) {
    var n = /([^?#]+)(\?[^#]*)?(#.*)?/.exec(e),
      r = '';
    if (n) {
      var i,
        o = n[1] || '',
        s = n[2] || '',
        n = n[3] || '';
      return (
        (r = this.getPartHash(e)
          ? ((i = n.indexOf('_tasdk')),
            -1 < n.indexOf('?')
              ? -1 < i
                ? o + s + '#' + n.substring(1, i) + '_tasdk=' + this.getCurrenId()
                : o + s + '#' + n.substring(1) + '&_tasdk=' + this.getCurrenId()
              : o + s + '#' + n.substring(1) + '?_tasdk=' + this.getCurrenId())
          : ((i = s.indexOf('_tasdk')),
            /^\?(\w)+/.test(s)
              ? -1 < i
                ? o + '?' + s.substring(1, i) + '_tasdk=' + this.getCurrenId() + n
                : o + '?' + s.substring(1) + '&_tasdk=' + this.getCurrenId() + n
              : o + '?' + s.substring(1) + '_tasdk=' + this.getCurrenId() + n)),
        t && (t.href = r),
        r
      );
    }
  }),
  (siteLinker.addClickListen = function () {
    function e(e) {
      var t,
        n = e.target,
        r = n.tagName.toLowerCase(),
        e = n.parentNode;
      (('a' === r && n.href) || (e && e.tagName && 'a' === e.tagName.toLowerCase() && e.href)) &&
        ((n = 'a' === r && n.href ? ((t = n.href), n) : ((t = e.href), e)),
        ('http:' !== (e = _.URL(t).protocol) && 'https:' !== e) || (i.getPartUrl(t) && i.rewriteUrl(t, n)));
    }
    var i = this;
    _.addSiteEvent(document, 'mousedown', e),
      window.PointerEvent &&
        'maxTouchPoints' in window.navigator &&
        0 <= window.navigator.maxTouchPoints &&
        _.addSiteEvent(document, 'pointerdown', e);
  }),
  (siteLinker.getUrlId = function () {
    var e = location.href.match(/_tasdk=([aufd][^\?\#\&\=]+)/);
    return _.check.isArray(e) && e[1] ? decodeURIComponent(e[1]) : '';
  }),
  (siteLinker.setRefferId = function () {
    var e = this.ta.getDistinctId(),
      t = this.getUrlId();
    if ('' === t) return !1;
    var n = 'd' === t.substring(0, 1);
    if ((t = t.substring(1)) === e) return !1;
    t && n && this.ta.identify(t);
  }),
  (siteLinker.init = function (e, t) {
    this.isInited ||
      ((this.isInited = !0),
      (this.ta = e),
      this.setRefferId(),
      _.check.isObject(t) &&
        _.check.isArray(t.linker) &&
        0 < t.linker.length &&
        (this.addClickListen(),
        (this.option = t.linker),
        (this.option = (function (e) {
          for (var t = e.length, n = [], r = 0; r < t; r++)
            /[A-Za-z0-9]+\./.test(e[r].part_url) &&
            '[object Boolean]' === Object.prototype.toString.call(e[r].after_hash)
              ? n.push(e[r])
              : Log.w('linker配置的第 ' + (r + 1) + ' 项格式不正确，请检查参数格式');
          return n;
        })(this.option))));
  });
var td = initAsModule();
export default td;
