/**
 * string-util
 *
 * @author fukui
 */
enum FormatType {
  i18n,
  default,
}

export const format = function (str: string, params: any) {
  return _format(str, params, FormatType.default);
};

const _format = function (str: string, params: any, type: FormatType) {
  str = str || '';
  if (Object.prototype.toString.call(params) !== '[object Object]') return str;
  for (const key in params) {
    const pattern = type === FormatType.default ? `\\{${key}\\}` : key;
    str = str.replace(new RegExp(pattern, 'g'), params[key]);
  }
  return str;
};

export const formatI18n = function (str: string, params: any) {
  return _format(str, params, FormatType.i18n);
};

export const combineURLs = function (baseURL: string, relativeURL: string): string {
  return relativeURL ? `${baseURL.replace(/\/+$/, '')}/${relativeURL.replace(/^\/+/, '')}` : baseURL;
};

export const leftPade = (str: string | number, len = 2, ch: number | string = ' ') => {
  str = String(str);
  let i = -1;
  len -= str.length;
  while (++i < len) {
    str = ch + str;
  }
  return str;
};

export const firstChartToUpperCase = (str: string): string => {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
};
