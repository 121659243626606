import i18n from '@falla/page-plugins/src/i18n';

export const changeLanguage = (lang: string) => {
  document.documentElement.setAttribute('lang', lang);
  i18n.i18n
    .changeLanguage(lang)
    .then(() => {
      console.log('changeLanguage success', lang);
    })
    .catch((err) => {
      console.log('changeLanguage error', err);
    });
};
