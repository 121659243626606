/*
 * @Description: 隐私政策
 * @Author: liaoxingfeng@iyinguo.com
 */

import { useStarkTranslation } from '@falla/hooks/src';
import useDocumentTitle from '@falla/hooks/src/useDocumentTitle';
import { format } from '@falla/utils/src/string-util';
import React from 'react';

import styles from './index.module.less';

interface IProps {}

const PrivacyPolicy: React.FC<IProps> = () => {
  const { t } = useStarkTranslation();
  useDocumentTitle(t('informationCollectionTitle', 'Statement of usage'), {
    restoreOnUnmount: true,
  });

  return (
    <div className={styles.privacyPolicy}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          {t('informationCollection', 'Information Collection and Use of Third-PartySDKs')}
        </div>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{
            __html: format(
              t(
                'informationCollectionContent',
                // eslint-disable-next-line max-len
                '',
              ),
              {
                loginTable: t(
                  'loginTable',
                  // eslint-disable-next-line max-len
                  '',
                ),
                audioTable: t(
                  'audioTable',
                  // eslint-disable-next-line max-len
                  '',
                ),
                otherTable: t(
                  'otherTable',
                  // eslint-disable-next-line max-len
                  '',
                ),
              },
            ),
          }}
        />
      </div>
    </div>
  );
};

export default React.memo(PrivacyPolicy);
