export enum LogType {
  INFO_ALL = '-1',
  API_RESPONSE = '1',
  INFO = '2',
  ERROR = '4',
  PROMISE_ERROR = '8',
  AJAX_ERROR = '16',
  SCRIPT_ERROR = '32',
  IMAGE_ERROR = '64',
  CSS_ERROR = '128',
  CONSOLE_ERROR = '256',
  MEDIA_ERROR = '512',
  RET_ERROR = '1024',
  REPORT = '2048',
  PV = '4096',
  EVENT = '8192',
  PAGE_NOT_FOUND_ERROR = '16384',
  WEBSOCKET_ERROR = '32768',
  BRIDGE_ERROR = '65536',
  LAZY_LOAD_ERROR = '131072',
}

interface ReportParams {
  msg: string;
  level?: LogType;
  ext1?: string;
  ext2?: string;
  ext3?: string;
  trace?: string;
}
export const report = (params: ReportParams) => {
  /*
// report 默认是 aegis.report 的日志类型，但是现在您可以传入任何日志类型了
aegis.report({
 msg: '这是一个ajax错误日志',
 level: Aegis.logType.AJAX_ERROR,
 ext1: 'ext1',
 ext2: 'ext2',
 ext3: 'ext3',
 trace: 'trace',
});
   */
  window.aegis?.report(params);
};

/**
 * error 用来表示 JS 错误日志，也是全量上报，一般用于开发者主动获取JS异常，然后进行上报
 */
export const error = (params: ReportParams | Error) => {
  window.aegis?.error(params);
};

interface EventLog {
  name: string;
  ext1?: string;
  ext2?: string;
  ext3?: string;
  from?: string;
}

export const reportEvent = (event: string | EventLog) => {
  window.aegis?.reportEvent(event);
};

export const ReportUtil = {
  report,
  reportEvent,
  error,
  LogType,
};

export default ReportUtil;
