/* eslint-disable no-console */
/* eslint-disable @iceworks/best-practices/no-http-url */
interface IVConsoleChangeAccount {
  name: string;
  id: string;
  isDev: boolean;
}
const ENV_CONFIG = {
  dev: {
    jsUrl: 'https://webf.fallalive.com/falla-admin/h5-change-account-plugin/hk/js/index.js',
    cssUrl: 'https://webf.fallalive.com/falla-admin/h5-change-account-plugin/hk/css/index.css',
  },
  prod: {
    jsUrl: 'https://webf.fallalive.com/falla-admin/h5-change-account-plugin/hk/js/index.js',
    cssUrl: 'https://webf.fallalive.com/falla-admin/h5-change-account-plugin/hk/css/index.css',
  },
};

class VConsoleChangeAccount {
  instance: any = null;
  isDev = true;
  constructor(option: IVConsoleChangeAccount) {
    console.log('option', option);
    this.isDev = option.isDev;
    this.init(option);
  }

  init(option: IVConsoleChangeAccount) {
    try {
      const instance = new window.VConsole.VConsolePlugin(option.name, option.id);
      this.instance = instance;
      instance.on('renderTab', this.onRenderTab);
      instance.on('ready', this.onReady.bind(this));

      return instance;
    } catch (error) {
      console.debug('VConsoleChangeAccount init error', error);
    }
  }

  onRenderTab(cb: Function) {
    cb(
      `<iframe id="vue-iframe" style="width:100%;position:absolute;top:0;bottom:0;min-height:100%;">
      </iframe>`,
    );
  }

  onReady() {
    const opt = this.isDev ? ENV_CONFIG.dev : ENV_CONFIG.prod;
    inject(opt);
  }
}

function inject({ jsUrl, cssUrl }: { jsUrl: string; cssUrl: string }) {
  const { contentWindow } = document.getElementById('vue-iframe');

  const div = contentWindow.document.createElement('div');
  div.setAttribute('id', 'ice-container');

  contentWindow.document.body.appendChild(div);

  const script = contentWindow.document.createElement('script');
  script.src = `${jsUrl}?v=${new Date().getTime()}`;
  contentWindow.document.body.appendChild(script);

  const link = contentWindow.document.createElement('link');
  link.href = `${cssUrl}?v=${new Date().getTime()}`;
  link.setAttribute('rel', 'stylesheet');
  link.setAttribute('type', 'text/css');
  contentWindow.document.body.appendChild(link);
}

export const initChangeAccountPlugin = function (vConsole: any, isDev: boolean) {
  const changeAccount = new VConsoleChangeAccount({
    id: 'falla',
    name: 'Falla',
    isDev,
  }).instance;
  vConsole.addPlugin(changeAccount);
};

export const getUserInfoByChangeAccountPlugin = function () {
  const userInfo = localStorage.getItem('v1.0_change_account_plugin');
  try {
    return JSON.parse(userInfo);
  } catch (error) {
    console.debug('getUserInfoByChangeAccountPlugin error', error, 'userInfo', userInfo);
  }
};

export const isExistChangeAccountPlugin = function () {
  const flag = localStorage.getItem('v1.0_vconsole_falla_plugin');
  try {
    return JSON.parse(flag) === true;
  } catch (error) {
    console.debug('isExistChangeAccountPlugin error', error, 'flag', flag);
  }
  return false;
};

export default VConsoleChangeAccount;
