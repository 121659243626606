import { IConfig } from '@falla/config/src/config';

export interface CustomPackageJson {
  name: string;
  version: string;
  custom?: {
    translateId: number;
    init?: {
      error?: boolean;
      i18n?: boolean;
      firebase?: boolean;
    };
    includesLang?: string[];
    defaultLang?: string;
  };
  [key: string]: any;
}

export enum InitTypes {
  firebase = 'firebase',
  error = 'error',
  flexible = 'flexible',
  i18n = 'i18n',
  report = 'report',
}

export interface PagePluginsOptions {
  package: CustomPackageJson;
  config: IConfig;
  isInMain: boolean;
  init: {
    firebase?: boolean;
    error?: boolean;
    flexible?: boolean;
    i18n?: boolean;
    report?: boolean;
  };
}
